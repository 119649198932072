<template>
  <v-switch
    color="primary"
    :value="pwaMode"
    @click="setPwaMode(!pwaMode)"
  ></v-switch>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PwaModeSwitch',
  computed: {
    ...mapGetters('app', [
      'pwaMode',
    ]),
  },
  methods: {
    ...mapActions('app', [
      'setPwaMode',
    ]),
  },
};
</script>
